<template>
  <v-dialog width="900" v-model="dialog" :fullscreen="$vuetify.breakpoint.xs">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="$store.getters['auth/isTattooer']"
        v-on="on"
        block
        style="height: 30px"
        elevation="0"
        @click="fetchTattooerTimetable"
        :disabled="disabled"
        v-text="$t('edit', { name: '' })"
      />
      <v-btn
        v-else
        @click="fetchStudioTimetable"
        style="height: 30px"
        v-on="on"
        elevation="0"
        block
        v-text="$t('edit', { name: '' })"
        :disabled="disabled"
      />
    </template>
    <v-card id="horario">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("timetable", { name: "" }) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <timetable ref="timetable" :timetable="user.timetable"></timetable>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="dialog = false"
          style="height: 30px; width: 100px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="save"
          style="height: 30px; width: 100px !important"
          elevation="0"
          ><v-icon small style="margin-right: 5px">$save</v-icon
          >{{ $t("save", { name: "" }) }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "TimetableStudio",
  props: ["id", "disabled"],
  data() {
    return {
      dialog: false,
      user: { timetable: [[], [], [], [], [], [], []] },
    };
  },

  mounted() {
    if (this.$store.getters["auth/isTattooer"]) this.fetchTattooerTimetable();
    if (this.$store.getters["auth/isStudio"]) this.fetchStudioTimetable();
  },
  methods: {
    ...mapActions("tattooers", [
      "getTattooerTimetable",
      "editTattooerTimetable",
    ]),
    fetchTattooerTimetable() {
      this.getTattooerTimetable(this.id).then((data) => {
        this.user = data;
      });
    },
    ...mapActions("studios", ["getStudioTimetable", "editStudioTimetable"]),
    fetchStudioTimetable() {
      //console.log("loading");
      this.getStudioTimetable(this.id).then((data) => {
        this.user = data;
      });
    },
    save() {
      let has = false;
      let has_all = false;
      this.$refs.timetable.createError();
      this.user.timetable.forEach((x) => {
        if (x.length) has = true;
        x.forEach((y) => {
          if (y[0] === null || y[1] === null || y[0] > y[1]) has_all = true;
        });
      });

      if (has_all) {
        return;
      }
      if (!has) {
        this.$alert(this.$t("must_have_timetable"));
        return;
      }
      if (this.$store.getters["auth/isTattooer"])
        this.editTattooerTimetable({
          tattooer_id: this.id,
          formData: this.user,
        }).then((data) => {
          this.dialog = false;
          this.$alert(this.$t("save_ok"), "", "");
        });
      if (this.$store.getters["auth/isStudio"])
        this.editStudioTimetable({
          studio_id: this.id,
          formData: this.user,
        }).then((data) => {
          this.dialog = false;
          this.$alert(this.$t("save_ok"), "", "");
        });
    },
  },
  components: {
    timetable: () => import("@/components/ui/Timetable"),
  },
};
</script>

<style lang="sass">
#horario
</style>